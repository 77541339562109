import chinaIcon from "../../img/icons/chinaIcon.png";
import europeanIcon from "../../img/icons/europeanIcon.png";
import turkeyIcon from "../../img/icons/turkeyIcon.png";
import indiaIcon from "../../img/icons/indiaIcon.png";
import koreaIcon from "../../img/icons/koreaIcon.png";
import iranIcon from "../../img/icons/iranIcon.png";
import taiwanIcon from "../../img/icons/taiwanIcon.png";
import vietnamIcon from "../../img/icons/vietnamIcon.png";
import uaeIcon from "../../img/icons/uaeIcon.png";

import LogoVMPP from "../../img/terminals/LogoVMPP.png";
import LogoVMT from "../../img/terminals/LogoVMT.png";
import LogoVSC from "../../img/terminals/LogoVSC.png";
import LogoVSCT from "../../img/terminals/LogoVSCT.png";

import LineImage from "../../img/icons/Line5.png";

import "./assistance.css";

const AssistanceComponent = () => {
  return (
    <section className="services" id="services__section">
      <div className="container">
        <div className="services__title">Мы можем:</div>
        <div className="services__content">
          <div className="services__text">
            <ul>
              <li>✓&nbsp;Перевезти грузы в большинство стран мира</li>
              <li>✓&nbsp;Оформить груз на таможне</li>
              <li>✓&nbsp;Застраховать грузы</li>
              <li>✓&nbsp;Сертифицировать товары</li>
              <li>✓&nbsp;Сопроводить сделки с поставщиками</li>
              <li>✓&nbsp;Выбрать оптимальный вид транспорта</li>
            </ul>
          </div>
          <div className="services__countries">
            <div className="services__countries-list">
              <ul>
                <li>
                  <img src={chinaIcon} alt="China flag" />
                  &nbsp;Китай
                </li>
                <li>
                  <img src={indiaIcon} alt="India flag" />
                  &nbsp;Индия
                </li>
                <li>
                  <img src={koreaIcon} alt="South Korea flag" />
                  &nbsp;Южная Корея
                </li>
                <li>
                  <img src={vietnamIcon} alt="Vietnam flag" />
                  &nbsp;Вьетнам
                </li>
                <li>
                  <img src={taiwanIcon} alt="Taiwan flag" />
                  &nbsp;Тайвань
                </li>
                <li>
                  <img src={uaeIcon} alt="UAE flag" />
                  &nbsp;ОАЭ
                </li>
                <li>
                  <img src={iranIcon} alt="Iran flag" />
                  &nbsp;Иран
                </li>
                <li>
                  <img src={turkeyIcon} alt="Türkiye flag" />
                  &nbsp;Турция
                </li>
                <li>
                  <img src={europeanIcon} alt="Europe flag" />
                  &nbsp;Европа
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="services__title">Сотрудничаем с терминалами:</div>
        <div className="services__terminals">
          <div className="services__terminals-group">
            <div>
              <img
                src={LogoVSCT}
                className="services__terminals-img"
                alt="Logo terminal VSCT"
              />
            </div>
            <div>
              <img
                src={LogoVMT}
                className="services__terminals-img"
                alt="Logo terminal VMT"
              />
            </div>
          </div>
          <div className="services__terminals-group">
            <div>
              <img
                src={LogoVSC}
                className="services__terminals-img"
                alt="Logo terminal VSC"
              />
            </div>
            <div>
              <img
                src={LogoVMPP}
                className="services__terminals-img"
                alt="Logo terminal VMPP"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="services__line">
        <img className="services__line-img" src={LineImage} alt="Design line" />
      </div>
    </section>
  );
};

export default AssistanceComponent;
