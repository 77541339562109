import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import sendToTelegram from "../../services/sendData/sendData.jsx";

import LineImage from "../../img/icons/Line5.png";
import "./contacts.css";

const ContactsComponent = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      sendToTelegram(event);
    }
    setValidated(true);
  };
  return (
    <section className="contacts" id="contacts__section">
      <div className="container">
        <div className="contacts__title">
          Отправьте заявку, и наш оператор перезвонит вам в течении дня:
        </div>
        <Row className="contacts__form">
          <Col sm={12} md={6}>
            <Form
              className="form"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <Form.Group className="inputs__group" controlId="formName">
                <FloatingLabel
                  controlId="floatingName"
                  label="Имя"
									className="inputs__group-text mb-0"
                >
                  <Form.Control
                    type="text"
                    required
                    name="name"
										placeholder="Имя"
                  />
                  <Form.Control.Feedback type="invalid" className="invalid__feedback">
                    Пожалуйста введите имя
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="inputs__group" controlId="formPhone">
                <FloatingLabel
                  controlId="floatingPhone"
                  label="Номер телефона"
                  className="inputs__group-text mb-0"
                >
                  <Form.Control
                    type="text"
                    required
                    name="phone"
                    placeholder="Введите номер телефона"
                    data-reg="^[0-9]+[0-9]*$"
                  />
                  <Form.Control.Feedback type="invalid" className="invalid__feedback">
                    Пожалуйста введите номер телефона
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="inputs__group" controlId="formEmail">
                <FloatingLabel
                  controlId="floatingEmail"
                  label="Email"
                  className="inputs__group-text mb-0"
                >
                  <Form.Control
                    type="email"
                    required
                    name="email"
                    placeholder="Введите email"
                  />
                  <Form.Control.Feedback type="invalid" className="invalid__feedback">
                    Пожалуйста введите email (name@example.com)
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="inputs__group" controlId="formFrom">
                <FloatingLabel
                  controlId="floatingFrom"
                  label="Отправка из..."
                  className="inputs__group-text mb-0"
                >
                  <Form.Control
                    type="text"
                    required
                    name="from"
                    placeholder="Введите точку отправки"
                  />
                  <Form.Control.Feedback type="invalid" className="invalid__feedback">
                    Пожалуйста введите точку отправки
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="inputs__group" controlId="formTo">
                <FloatingLabel
                  controlId="floatingTo"
                  label="Доставка в..."
                  className="inputs__group-text mb-0"
                >
                  <Form.Control
                    type="text"
                    required
                    name="to"
                    placeholder="Введите точку прибытия"
                  />
                  <Form.Control.Feedback type="invalid" className="invalid__feedback">
                    Пожалуйста введите точку прибытия
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="form__info">
                <Form.Text className="text-muted mb-3">
                  <a
                    href="#contacts__section"
                    onClick={handleShow}
                    className="form__info-title"
                  >
                    Отправляя форму вы соглашаетесь с политикой
                    конфиденциальности Сайта
                  </a>
                </Form.Text>
              </Form.Group>
              <Form.Group className="contacts__form-wrapper">
                <button className="contacts__form-btn mb-3" type="submit">
                  Рассчитать
                </button>
							</Form.Group>
							<Form.Group className="form__result">
                <div className="form__send-result"></div>
              </Form.Group>
            </Form>
          </Col>
          <Col sm={12} md={6}>
            <div className="contacts__adress">
              <div className="contacts__adress-position pt-0 mb-3">
                <iframe
                  title="map"
                  className="contacts__adress-map"
                  src="https://yandex.ru/map-widget/v1/?indoorLevel=1&ll=30.472566%2C59.864419&mode=search&oid=94430340797&ol=biz&z=19.42"
                ></iframe>
              </div>
              <div className="contacts__adress-phone">
                <a
                  className="contacts__adress-link"
                  href="tel:+7(800)555-44-33"
                >
                  +7(950)027-37-90
                </a>
              </div>
              <div className="contacts__adress-email">
                <a
                  href="mailto:info@aiapi-trans.ru"
                  className="contacts__adress-link"
                  underline="hover"
                  color="inherit"
                >
                  info@aiapi-trans.ru
                </a>
              </div>
              <div className="contacts__adress-office">
                <a
                  className="contacts__adress-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://yandex.ru/maps/-/CDaWyHzG"
                >
                  Санкт-Петербург, просп. Обуховской Обороны, 116Е, корп. 1
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Modal size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Политика конфиденциальности</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>1. Общие положения</strong>
          </p>
          <p>
            Настоящая политика обработки персональных данных составлена в
            соответствии с требованиями Федерального закона от 27.07.2006.
            №152-ФЗ «О персональных данных» и определяет порядок обработки
            персональных данных и меры по обеспечению безопасности персональных
            данных, предпринимаемые ООО "Aiapi-Trans" (далее – Сайт).
          </p>
          <p>
            Настоящая политика Сайта в отношении обработки персональных данных
            (далее – Политика) применяется ко всей информации, которую Сайт
            может получить о посетителях веб-сайта https://aiapi-trans.ru.
          </p>
          <p>
            Сайт обрабатывает персональные данные Пользователя только в случае
            их заполнения и/или отправки Пользователем самостоятельно через
            специальные формы, расположенные на сайте https://aiapi-trans.ru.
            Заполняя соответствующие формы и/или отправляя свои персональные
            данные через Сайт, Пользователь выражает свое согласие с данной
            Политикой.
          </p>
          <p>
            Персональные данные Пользователя никогда, ни при каких условиях не
            будут переданы третьим лицам, за исключением случаев, связанных с
            исполнением действующего законодательства.
          </p>
          <p>
            Срок обработки персональных данных является неограниченным.
            Пользователь может в любой момент отозвать свое согласие на
            обработку персональных данных, направив уведомление посредством
            электронной почты на электронный адрес info@aiapi-trans.ru с
            пометкой «Отзыв согласия на обработку персональных данных».
          </p>
          <p>
            Пользователь может получить любые разъяснения по интересующим
            вопросам, касающимся обработки его персональных данных, обратившись
            с помощью электронной почты info@aiapi-trans.ru.
          </p>
          <p>
            <strong>2. Основные понятия</strong>
          </p>
          <p>
            Персональные данные – любая информация, относящаяся прямо или
            косвенно к определенному или определяемому Пользователю веб-сайта
            https://aiapi-trans.ru;
          </p>
          <p>
            Пользователь – любой посетитель веб-сайта https://aiapi-trans.ru;
          </p>
          <p>
            <strong>
              3. Сайт может обрабатывать следующие персональные данные
              Пользователя
            </strong>
          </p>
          <ul>
            <li>&#8226; Фамилия, имя, отчество;</li>
            <li>&#8226; Электронный адрес;</li>
            <li>&#8226; Номера телефонов;</li>
            <li>
              &#8226; Вышеперечисленные данные далее по тексту Политики
              объединены общим понятием Персональные данные.
            </li>
            <li>
              &#8226; Также на сайте происходит сбор и обработка обезличенных
              данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов
              интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).
            </li>
          </ul>
          <p>
            <strong>4. Цели обработки персональных данных</strong>
          </p>
          <p>
            Цель обработки персональных данных Пользователя — создание заявки на
            перевозку, уточнение деталей заказа. Персональные данные
            используется для исполнения конкретного договора с конкретным
            человеком/органицацией.
          </p>
          <p>
            Также Сайт имеет право направлять Пользователю уведомления о новых
            продуктах и услугах, специальных предложениях и различных событиях.
            Пользователь всегда может отказаться от получения информационных
            сообщений, направив письмо на адрес электронной почты
            info@aiapi-trans.ru с пометкой «Отказ от уведомлений о новых
            продуктах и услугах и специальных предложениях».
          </p>

          <p>
            Обезличенные данные Пользователей, собираемые с помощью сервисов
            интернет-статистики, служат для сбора информации о действиях
            Пользователей на сайте, улучшения качества сайта и его содержания.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="contacts__line">
        <img className="contacts__line-img" src={LineImage} alt="Design line" />
      </div>
    </section>
  );
};

export default ContactsComponent;
