import AboutComponent from "./components/about/About";
import BenefitsComponent from "./components/benefits/Benefits";
import ContactsComponent from "./components/contacts/Contacts";
import DeliveryComponent from "./components/delivery/Delivery";
import FooterComponent from "./components/footer/Footer";
import HeaderComponent from "./components/header/Header";
import MainComponent from "./components/main/Main";
import AssistanceComponent from "./components/assistance/Assistance";

function App() {
  return (
    <div className="App">
      <HeaderComponent />
      <MainComponent />
      <AboutComponent />
      <DeliveryComponent />
			<AssistanceComponent />
			<BenefitsComponent />
			<ContactsComponent />
			<FooterComponent />
    </div>
  );
}

export default App;
