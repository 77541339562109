import securityIcon from "../../img/icons/securityIcon.png";
import locationIcon from "../../img/icons/locationIcon.png";
import warehouseIcon from "../../img/icons/warehouseIcon.png";
import routeIcon from "../../img/icons/routeIcon.png";
import officeIcon from "../../img/icons/officeIcon.png";

import "./benefits.css";

const BenefitsComponent = () => {
  return (
    <section className="benefits" id="benefits__section">
      <div className="container">
        <div className="benefits__title">
          Преимущества сотрудничества с нами:
        </div>
        <div className="benefits__content">
          <div className="image-parlax">
            <div className="image-container">
              <div className="top front-side">
                <div className="inner">
                  <div className="benefits__content-block">
                    <div className="content__block">
                      <div className="block__office block__twice">
                        <img
                          src={officeIcon}
                          className="icon"
                          alt="Office Icon"
                        />
                        <div className="block__title">
                          Офис в Санкт-Петербурге
                        </div>
                      </div>
                      <div className="block__pay block__twice">
                        <div className="block__title">Нет скрытых платежей</div>
                        <img
                          src={securityIcon}
                          className="icon"
                          alt="Security Icon"
                        />
                      </div>
                    </div>

                    <div className="block__key block__ones">
                      <div className="block__title">
                        Таможенное оформление “под ключ”
                      </div>
                      <div className="block__text">
                        Специалист компании классифицирует товар по коду ТНВЭД,
                        готовит весь набор документов для подачи и подает
                        таможенную декларацию
                      </div>
                    </div>

                    <div className="content__block">
                      <div className="block__location block__twice">
                        <img
                          src={locationIcon}
                          className="icon"
                          alt="Location Icon"
                        />
                        <div className="block__title">
                          Гарантия доставки груза
                        </div>
                      </div>
                      <div className="block__route block__twice">
                        <div className="block__title">
                          Подбор оптимального маршрута
                        </div>
                        <img
                          src={routeIcon}
                          className="icon"
                          alt="Route Icon"
                        />
                      </div>
                    </div>

                    <div className="block__warehouse block__ones">
                      <div className="block__title">
                        Организация складского хранения
                      </div>
                      <img
                        src={warehouseIcon}
                        className="icon"
                        alt="Warehouse Icon"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="back-side">
                <div className="inner">
                  <div className="benefits__content-block">
                    <div className="content__block">
                      <div className="block__office block__twice">
                        <img
                          src={officeIcon}
                          className="icon"
                          alt="Office Icon"
                        />
                        <div className="block__title">
                          Офис в Санкт-Петербурге
                        </div>
                      </div>
                      <div className="block__pay block__twice">
                        <div className="block__title">Нет скрытых платежей</div>
                        <img
                          src={securityIcon}
                          className="icon"
                          alt="Security Icon"
                        />
                      </div>
                    </div>

                    <div className="block__key block__ones">
                      <div className="block__title">
                        Таможенное оформление “под ключ”
                      </div>
                      <div className="block__text">
                        Специалист компании классифицирует товар по коду ТНВЭД,
                        готовит весь набор документов для подачи и подает
                        таможенную декларацию
                      </div>
                    </div>

                    <div className="content__block">
                      <div className="block__location block__twice">
                        <img
                          src={locationIcon}
                          className="icon"
                          alt="Location Icon"
                        />
                        <div className="block__title">
                          Гарантия доставки груза
                        </div>
                      </div>
                      <div className="block__route block__twice">
                        <div className="block__title">
                          Подбор оптимального маршрута
                        </div>
                        <img
                          src={routeIcon}
                          className="icon"
                          alt="Route Icon"
                        />
                      </div>
                    </div>

                    <div className="block__warehouse block__ones">
                      <div className="block__title">
                        Организация складского хранения
                      </div>
                      <img
                        src={warehouseIcon}
                        className="icon"
                        alt="Warehouse Icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsComponent;
