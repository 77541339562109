import { Col, Container, Row } from "react-bootstrap";

import LineImage from "../../img/icons/Line5.png";
import aboutImage from "../../img/images/services-transport.png";
import "./about.css";

const AboutComponent = () => {
  return (
    <section className="about" id="about__section">
      <div className="about__line">
        <img className="about__line-img" src={LineImage} alt="Design Line" />
      </div>
      <Container className="container">
        <div className="about__header">
          <div className="about__header-title">О компании:</div>
        </div>
        <Row className="about__content">
          <Col xs={12} md={5} className="about__img">
            <img src={aboutImage} alt="Transports" />
          </Col>
          <Col xs={12} md={7} className="about__text">
            <ul className="about__text-list">
              <li className="about__text-first">
                Компания <span className="selected-text">AIAPI-TRANS</span>{" "}
                спе&shy;ци&shy;а&shy;ли&shy;зи&shy;ру&shy;ет&shy;ся на внешне&shy;экономической деятель&shy;ности:
                между&shy;народ&shy;ные пере&shy;возки грузов (экспорт/<wbr/>импорт), та&shy;мо&shy;жен&shy;ное
                оформле&shy;ние, стра&shy;хование, сер&shy;тифи&shy;кация товаров, тер&shy;минальная
                обработка грузов, соп&shy;ровожде&shy;ние сделок во всех странах мира.
              </li>
            </ul>
          </Col>
          <Col xs={12} md={12} className="about__text-down">
            <div>
              Мы занимаемся транспортировкой стан&shy;дарт&shy;ных, сборных (LCL),
              негабарит&shy;ных, термо&shy;чувствитель&shy;ных, опасных и тя&shy;жело&shy;весных грузов
              от 10 кг до 27 тонн и предоставляем полный цикл перевозки на
              территории стран Кореи, Турции, ОАЭ, обеспечивая бес&shy;перебой&shy;ную и
              эффектив&shy;ную доставку грузов любого типа (текстиль, прессы, сложное
              оборудование, кухонные при&shy;надлеж&shy;ности). Спе&shy;ци&shy;алис&shy;ты компании –
              юристы, логисты, таможенные пред&shy;ста&shy;ви&shy;тели, води&shy;тели, транс&shy;порт&shy;ные
              агенты в Санкт-Петербурге и регионах – ежедневно трудятся над тем,
              чтобы задачи наших клиентов решались вовремя и по оптимальным
              схемам .
            </div>
          </Col>
          <Col className="about__text-down-end animate__animated animate__zoomIn animate__delay-2s">
            <div>
              Приглашаем Вас к сотрудничеству по всем направлениям нашей
              деятельности!
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutComponent;
