const TOKEN = process.env.REACT_APP_TOKEN;
const CHAT_ID = process.env.REACT_APP_CHAT_ID;
const API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

const sendToTelegram = async (event) => {
  event.preventDefault();

  const form = event.target;
  const formBtn = document.querySelector(".contacts__form-wrapper button");
  const formSendResult = document.querySelector(".form__send-result");
  formSendResult.textContent = "";

  const { name, email, phone, from, to } = Object.fromEntries(
    new FormData(form).entries()
  );
  const text = `Заявка от ${name}!\nТелефон: ${phone}\nEmail: ${email}\nОткуда: ${from}\nКуда: ${to}`;
  try {
    formBtn.textContent = "Загрузка...";
    const response = await fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chat_id: CHAT_ID,
        text: text,
      }),
    });

    if (response.ok) {
      formSendResult.textContent =
        "Заявка отправлена! Мы свяжемся с вами в ближайшее время.";
      formSendResult.style.cssText = "color: black; border: 1px solid #00e531";
      form.reset();
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    console.error(error);
    formSendResult.textContent =
      "Заявка не отправлена! Попробуйуте позже или позвоните нам.";
			formSendResult.style.cssText = "color: black; border: 1px solid red; background: red";
  } finally {
    formBtn.textContent = "Рассчитать";
  }
};

export default sendToTelegram;
