import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";

import Train from "../../img/images/main-cargoTrain.jpg";
import Ship from "../../img/images/main-cargoShip.jpg";
import Plane from "../../img/images/main-cargoPlane.jpg";
import Truck from "../../img/images/main-cargoTruck.jpg";
import "./main.css";

const MainComponent = () => {
  return (
    <section className="main" id="main__section">
      <Carousel fade controls={false} pause={false}>
        <Carousel.Item>
          <div className="item-img">
            <Image
              src={Train}
              className="carousel__img d-block"
              alt="Cargo train"
              text="First slide"
            />
          </div>
          <Carousel.Caption className="carousel__caption">
            <div className="container">
              <div className="main__content row">
                <div className="main__text main__text-first col-md-8 col-sm-12">
                  <div className="main__title">
                    «AIAPI-TRANS» LLC <br></br>
                    <div className="main__title-2">
                      Железнодорожные ПЕРЕВОЗКИ
                    </div>
                  </div>
                  <div className="main__description animate__animated animate__fadeInLeftBig">
                    Международные транспортировки грузов из Европы, Китая, Индии
                    и многих других стран
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="main__btn-wrapper">
                    <a className="main__btn" href="#delivery__section">
                      Подробнее
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="item-img">
            <Image
              src={Ship}
              className="carousel__img d-block"
              alt="Cargo ship"
              text="Second slide"
            />
          </div>
          <Carousel.Caption className="carousel__caption">
            <div className="container">
              <div className="main__content row">
                <div className="main__text main__text-second col-md-8 col-sm-12">
                  <div className="main__title">
                    «AIAPI-TRANS» LLC <br></br>
                    <div className="main__title-2">
                      Мультимодальные перевозки
                    </div>
                  </div>
                  <div className="main__description animate__animated animate__fadeInRightBig">
                    Международные транспортировки грузов из Европы, Китая, Индии
                    и многих других стран
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="main__btn-wrapper">
                    <a className="main__btn" href="#delivery__section">
                      Подробнее
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="item-img">
            <Image
              src={Truck}
              className="carousel__img d-block"
              alt="Cargo truck"
              text="Third slide"
            />
          </div>
          <Carousel.Caption className="carousel__caption">
            <div className="container">
              <div className="main__content row">
                <div className="main__text main__text-fourth col-md-8 col-sm-12">
                  <div className="main__title">
                    «AIAPI-TRANS» LLC<br></br>
                    <div className="main__title-2">
                      Международные автоперевозки
                    </div>
                  </div>
                  <div className="main__description animate__animated animate__fadeInDownBig">
                    Международные транспортировки грузов из Европы, Китая, Индии
                    и многих других стран
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="main__btn-wrapper">
                    <a className="main__btn" href="#delivery__section">
                      Подробнее
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="item-img">
            <Image
              src={Plane}
              className="carousel__img d-block"
              alt="Cargo plane"
              text="Third slide"
            />
          </div>
          <Carousel.Caption className="carousel__caption">
            <div className="container">
              <div className="main__content row">
                <div className="main__text main__text-third col-md-8 col-sm-12">
                  <div className="main__title">
                    «AIAPI-TRANS» LLC<br></br>
                    <div className="main__title-2">
                      Международные авиаперевозки
                    </div>
                  </div>
                  <div className="main__description animate__animated animate__fadeInUpBig">
                    Международные транспортировки грузов из Европы, Китая, Индии
                    и многих других стран
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="main__btn-wrapper">
                    <a className="main__btn" href="#delivery__section">
                      Подробнее
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </section>
  );
};

export default MainComponent;
