import { Container, Image } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

import trainImage from "../../img/transport/delivery-train.png";
import planeImage from "../../img/transport/delivery-plane.png";
import shipImage from "../../img/transport/delivery-ship.png";
import truckImage from "../../img/transport/delivery-truck.png";
import "./delivery.css";

const DeliveryComponent = () => {
  return (
    <section className="delivery" id="delivery__section">
      <Container className="container delivery__container">
        <div className="delivery__header">
          <div className="delivery__header-title">Виды грузоперевозок:</div>
        </div>
        <Accordion
          defaultActiveKey={["1"]}
          alwaysOpen
          className="delivery__accordion"
        >
          <Accordion.Item eventKey="1">
            <Accordion.Header className="delivery__accordion-header">
              Ж/Д перевозки
            </Accordion.Header>
            <Accordion.Body className="delivery__accordion-body">
              <Image
                className="delivery__card-img"
                src={trainImage}
                alt="Small Cargo Train"
              />
              <ul>
                <br></br>
                <li>
                  <span className="body__text-selected">
                    Большой объем грузов{" "}
                  </span>
                  - за счет высокой грузо&shy;подъем&shy;но&shy;сти же&shy;лез&shy;но&shy;до&shy;рож&shy;ного состава
                  воз&shy;можно отправить много&shy;тон&shy;ные тя&shy;жело&shy;вес&shy;ные грузы, которые не
                  могут перевезти другие виды транспорта, а разнообразие видов
                  вагонов позволяет пере&shy;возить различные виды груза.
                </li>
                <br></br>
                <li>
                  <span className="body__text-selected">Надежность </span>-
                  контейнерные пере&shy;возки ЖД транс&shy;порт&shy;ом надежны и безопасны, так
                  как движение поездов не зависит от погодных условий и поставка
                  всегда приходит вовремя, а вероятность аварийных ситуаций
                  значительно ниже авто&shy;мобиль&shy;ных пере&shy;воз&shy;ок.
                </li>
                <br></br>
                <li>
                  <span className="body__text-selected">
                    Дальние расстояния{" "}
                  </span>
                  - отгрузка кон&shy;тей&shy;не&shy;ров возможна в (из) Китай, Монголию, страны
                  Европы, Азии,СНГ, Балтии и по регионам России.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" id="target2">
            <Accordion.Header className="delivery__accordion-header">
              Мультимодальные перевозки
            </Accordion.Header>
            <Accordion.Body className="delivery__accordion-body">
              <ul>
                <li>
                  <span className="body__text-selected">Универсальность </span>-
                  эффект&shy;ив&shy;ное со&shy;че&shy;та&shy;ние воз&shy;мож&shy;но&shy;стей мор&shy;ско&shy;го, железно&shy;дорожно&shy;го,
                  авто&shy;мобиль&shy;ного и воз&shy;душ&shy;ного транс&shy;порта позволяет опе&shy;ра&shy;ти&shy;вно
                  дос&shy;тавлять грузы в любой населенный пункт.
                </li>
                <br></br>
                <li>
                  <span className="body__text-selected">
                    Дальние расстояния{" "}
                  </span>
                  - мульти&shy;модаль&shy;ные пере&shy;возки о&shy;со&shy;бен&shy;но актуальны на
                  меж&shy;континент&shy;аль&shy;ных на&shy;прав&shy;ле&shy;ни&shy;ях, когда необходим доступ в
                  удаленные точки мира, где один вид транспорта бессилен.
                </li>
                <br></br>
                <li>
                  <span className="body__text-selected">
                    Экономическая выгода{" "}
                  </span>
                  - для каж&shy;до&shy;го участка пути под&shy;бир&shy;ается со&shy;от&shy;вет&shy;ству&shy;ющий тип
                  транспорта, что позволяет опти&shy;ми&shy;зиро&shy;вать за&shy;тра&shy;ты на доставку.
                </li>
              </ul>
              <Image
                className="delivery__card-img"
                src={shipImage}
                alt="Small Cargo Ship"
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header className="delivery__accordion-header">
              Международные автоперевозки
            </Accordion.Header>
            <Accordion.Body className="delivery__accordion-body">
              <Image
                className="delivery__card-img"
                src={truckImage}
                alt="Small Cargo Truck"
              />
              <ul>
                <li>
                  <span className="body__text-selected">
                    Доставка от двери до двери{" "}
                  </span>
                  - позволяет доставить груз прямиком к месту наз&shy;на&shy;че&shy;ни&shy;я.
                </li>
                <br></br>
                <li>
                  <span className="body__text-selected">
                    Регулирование времени дос&shy;тав&shy;ки{" "}
                  </span>
                  - авто&shy;перевоз&shy;ки дают воз&shy;мож&shy;ность выбрать время доставки, в то
                  время как авиа, жд и морские строго привязаны ко времени
                  при&shy;бы&shy;ти&shy;я. При этом авто&shy;перевозка менее чув&shy;стви&shy;тель&shy;на к
                  погодным из&shy;ме&shy;не&shy;ни&shy;ям, что снижает риск изменения сроков
                  доставки.
                </li>
                <br></br>
                <li>
                  <span className="body__text-selected">
                    Круглосуточная доставка{" "}
                  </span>
                  - движение ко&shy;лес&shy;но&shy;го транс&shy;порта может осуществляться
                  круг&shy;ло&shy;су&shy;точ&shy;но, что делает доставку в/от портов, ЖД-вокзалов,
                  аэро&shy;порт&shy;ов мак&shy;си&shy;маль&shy;но оперативной.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header className="delivery__accordion-header">
              Международные авиаперевозки, сборные грузы
            </Accordion.Header>
            <Accordion.Body className="delivery__accordion-body">
              <ul>
                <li>
                  <span className="body__text-selected">Быстрая доставка </span>
                  - авиа&shy;перевоз&shy;ки способны за считанные часы доставить груз на
                  другой континент. Это помогает при перевозках скоропортящихся
                  товаров. Отличный вариант отправить ме&shy;ди&shy;цин&shy;с&shy;кую и
                  фарма&shy;цевти&shy;ческую продукцию. В рамках данного пред&shy;ложе&shy;ния к
                  перевозке при&shy;ни&shy;мают&shy;ся грузы всех категорий за исключе&shy;нием
                  опасных грузов (только по пред&shy;варитель&shy;ному сог&shy;ласова&shy;нию).
                </li>
                <br></br>
                <li>
                  <span className="body__text-selected">
                    Перевозка на большие рас&shy;сто&shy;яния{" "}
                  </span>
                  - вне зависимости от географического рас&shy;по&shy;ло&shy;же&shy;ния,
                  авиадоставка спо&shy;соб&shy;на перевезти товар в самые отдаленные
                  пункты наз&shy;на&shy;че&shy;ния.
                </li>
                <br></br>
                <li>
                  <span className="body__text-selected">
                    Удобство планирования{" "}
                  </span>
                  - рейсы по многим нап&shy;рав&shy;ле&shy;ниям вы&shy;пол&shy;ня&shy;ю&shy;тся ежедневно.
                </li>
              </ul>
              <Image
                className="delivery__card-img"
                src={planeImage}
                alt="Small Cargo Plane"
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="delivery__btn-wrapper">
          <a className="delivery__btn" href="#contacts__section">
            Заказать перевозку
          </a>
        </div>
      </Container>
    </section>
  );
};
export default DeliveryComponent;
