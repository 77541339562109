import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";

import LogoImage from "../../img/logo/aiapi_logo.svg";
import LineImage from "../../img/icons/Line5.png";
import "./header.css";
import { useState, useEffect } from "react";
import React from "react";

function HeaderComponent() {
  // scroll direction hook
  function useScrollDirection() {
    const [isHidden, setIsHidden] = useState(false);

    useEffect(() => {
      // store the last scrolled Y to detect how fast users scroll pages
      let lastScrollY = window.pageYOffset;

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const goingDown = scrollY > lastScrollY;
        const diff = 4;
        // There are two cases that the header might want to change the state:
        // - when scrolling up but the header is hidden
        // - when scrolling down but the header is shown
        // stateNotMatched variable decides when to try changing the state
        const stateNotMatched = goingDown !== isHidden;
        const scrollDownTooFast = scrollY - lastScrollY > diff;
        const scrollUpTooFast = scrollY - lastScrollY < -diff;

        const shouldToggleHeader =
          stateNotMatched && (scrollDownTooFast || scrollUpTooFast);
        if (shouldToggleHeader) {
          setIsHidden(goingDown);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };

      window.addEventListener("scroll", updateScrollDirection);
      return () => {
        window.removeEventListener("scroll", updateScrollDirection);
      };
    }, [isHidden]);

    return isHidden;
  }

  const isHidden = useScrollDirection();
  return (
    <header
      className={`header ${isHidden ? "header-hide" : "header-show"}`}
      id="header__section"
    >
      <Navbar expand="lg" collapseOnSelect className="navbar">
        <Container fluid className="container">
          <Navbar.Brand href="/">
            <div className="header__logo">
              <img
                className="header__logo-img"
                src={LogoImage}
                alt="Company logo"
              />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Offcanvas id="navbarScroll" placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel-expand">
                Меню
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="offcanvas__body">
              <Nav
                className="header__nav justify-content-center flex-grow-1 pe-3"
                fill
                variant="tabs"
              >
                <Nav.Link href="#main__section" className="header__nav-link">
                  Главная
                </Nav.Link>
                <Nav.Link href="#about__section" className="header__nav-link">
                  О компании
                </Nav.Link>
                <Nav.Link
                  href="#delivery__section"
                  className="header__nav-link"
                >
                  Виды перевозок
                </Nav.Link>
                <Nav.Link
                  href="#services__section"
                  className="header__nav-link"
                >
                  Услуги
                </Nav.Link>
                <Nav.Link
                  href="#benefits__section"
                  className="header__nav-link"
                >
                  Приемущества
                </Nav.Link>
                <Nav.Link
                  href="#contacts__section"
                  className="header__nav-link"
                >
                  Контакты
                </Nav.Link>
              </Nav>
              <Form className="d-flex header__contacts animate__animated animate__headShake animate__delay-2s">
                <ul>
                  <li>
                    <a className="header__link" href="tel:+7(800)555-44-33">
                      +7(950)027-37-90
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:info@aiapi-trans.ru"
                      className="header__link"
                      underline="hover"
                      color="inherit"
                    >
                      info@aiapi-trans.ru
                    </a>
                  </li>
                </ul>
              </Form>
              <div className="header__line">
                <img
                  className="header__line-img"
                  src={LineImage}
                  alt="Design line"
                />
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </header>
  );
}

export default HeaderComponent;
